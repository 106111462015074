<template>
  <section class="courses">
    <h1 id="page-title" class="d-md-block d-none">
      {{ $t('courses') }}
    </h1>
    <div>
      <b-row>
        <b-col xl="4" cols="6">
          <router-link :to="{ name: 'StudentLightCourses' }" class="course-switch">
            <JoystickSvg/>{{ $t('lightcourse') }}
          </router-link>
        </b-col>
        <b-col xl="4" cols="6">
          <router-link :to="{ name: 'StudentMediumCourses' }" class="course-switch">
            <StudentSvg/>{{ $t('mediumcourse') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
    <div class="mt-4">
      <router-view/>
    </div>
  </section>
</template>

<script>
import JoystickSvg from '@/assets/svg/joystick.svg'
import StudentSvg from '@/assets/svg/student2.svg'

export default {
  name: "StudentCourses",
  components: {
    JoystickSvg,
    StudentSvg,
  },
  metaInfo() {
    return {
      title: this.$t('courses'),
    }
  },
  created() {
    this.$store.commit('setPageMobileTitle', this.$t('courses'))
  },
  beforeDestroy() {
    this.$store.commit('setPageMobileTitle', '')
  }
}
</script>

<style scoped lang="scss">
  .course-switch {
    color: #656A75;
    svg {
      width: 24px;
      fill: #656A75;
      margin-right: 12px;
    }
    padding: 0 17px;
    height: 60px;
    width: 100%;
    text-align: center;
    border: 1px solid #656A75;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    &.router-link-active {
      border: 1px solid #E6F7FF;
      background: #E6F7FF;
      color: $text-themed;
      svg {
        fill: $text-themed;
      }
    }
  }
  @media(max-width: 768px) {
    .course-switch {
      padding: 7px 10px;
      font-size: 13px;
      height: 40px;
      svg {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
</style>
